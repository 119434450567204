import React, { useState, useEffect, useContext } from 'react'
// import LocationsTabContainer from '../../../../../containers/backoffice/Common/Locations'
// import UsersTabContainer from '../../../../../containers/backoffice/Common/Users'
// import BiohazardTabContainer from '../../../../../containers/backoffice/Covid/Biohazard'
import ReservationsTabContainer from '../../../../../containers/backoffice/WsManager/Reservations'
// import LogTabContainer from '../../../../../containers/backoffice/Common/Log'
// import ReportsTabContainer from '../../../../../containers/backoffice/Common/Reports'
// import SettingsTabContainer from '../../../../../containers/backoffice/Common/Settings'
// import ContactReportsTabContainer from '../../../../../containers/backoffice/Covid/ContactReports'
// import CheckInReportsTabContainer from '../../../../../containers/backoffice/Covid/CheckInReports'
import { injectIntl } from 'react-intl';
// import { APP } from '../../../../../interfaces/SwitchState';
import { AbilityContext } from '../../../../../utils/permissions/Can'
// import BookingTabContainer from '../../../../../containers/backoffice/Common/Booking'
import './NewReservation.scss'
import Form from '../Form/Form'
import ConfirmForm from '../ConfirmForm/ConfirmForm'
import { FormReservation } from '../../../../../models/Reservation'
import { LocationUtils } from '../../../../../utils/LocationUtils'
import moment from 'moment';

const NewReservation = (props: any) => {
    // const { formatMessage } = props.intl;
    const [activeIndex, setActiveIndex] = useState(0)
    const [formDetail, setFormDetail] = useState<FormReservation>(new FormReservation())
    const [stepForm, setStepForm] = useState<number>(0)
    const [stepConfirmForm, setStepConfirmForm] = useState<number>(0)
    const [showList, setShowList] = useState<number>(0)
    const [optionsLocations, setOptionsLocations] = useState<any[]>([])
    const [optionsFloors, setOptionsFloors] = useState<any[]>([])
    const [optionsRooms, setOptionsRooms] = useState<any[]>([])
    const [optionsAreas, setOptionsAreas] = useState<any[]>([])
    const [optionsDesks, setOptionsDesks] = useState<any[]>([])
    const [listLocation, setListLocation] = useState<any[]>([])
    const [listFloor, setListFloor] = useState<any[]>([])
    const [listArea, setListArea] = useState<any[]>([])
    const [listRoom, setListRoom] = useState<any[]>([])
    const [listDesk, setListDesk] = useState<any[]>([])
    const [selectedLocation, setSelectedLocation] = useState<any>()
    const [selectedArea, setSelectedArea] = useState<any>()
    const [selectedFloor, setSelectedFloor] = useState<any>()
    const [selectedItem, setSelectedItem] = useState<any>()

    const [selectedRoom, setSelectedRoom] = useState<any>()
    const [selectedDesk, setSelectedDesk] = useState<any>()
    const [selectedReason, setSelectedReason] = useState<any>()
    const [depthLocation, setDepthLocation] = useState<number>(0)
    const [loading, setLoading] = useState<any>(false)
    const [optionsMotivation, setOptionsMotivation] = useState<any[]>([])
    const [optionsPeople, setOptionsPeople] = useState<any[]>([])
    const [selectedUsers, setSelectedUsers] = useState<any>([])
    const ability = useContext(AbilityContext);
    const [range, setRange] = useState([moment().startOf('day').add(1, 'days').toDate(), moment().endOf('day').add(1, 'days').toDate()])
    const [startDate, setStartDate] = useState<any>(moment().startOf('day').add(1, 'days').format('YYYY-MM-DD').split("T")[0])
    const [endDate, setEndDate] = useState<any>(moment().endOf('day').add(1, 'days').format('YYYY-MM-DD').split("T")[0])
    const [step, setStep] = useState<boolean>(false)
    let allTabs: any[] = [];
    const [visibleItem, setVisibleItem] = useState<any>({
        area: true,
        floor: true,
        room: true,
        desk: true
    })
    const [successBooking, setSuccessBooking] = useState<any>(false)

    // console.log("Home Component")
    // console.log("HOME PROPS", props)
    // console.log("HOME PROPS AUTH", props.auth)
    // console.log("[Home Component] USERS LIST", props.usersList)
    // console.log("HOME PROPS AUTH", props.auth)
    // console.log("[Home Component] LOCATIONS LIST", props.locationsList)
    // console.log("[Home Component] RESERVATIONS LIST", props.reservationsList)
    // console.log("[Home Component] REASON LIST", props.reasonList)
    // console.log("[Home Component] RESERVATION CREATE LIST", props.reservationCreate)
    // console.log("[Home Component] RESERVATION DELETE LIST", props.reservationDelete)
    // console.log("[Home Component] CERTIFICATION LIST DONE", props.acceptCertificationDone)
    // console.log("[Home Component] CONFIGURATION DONE", props.acceptCertificationDone)
    // console.log("[Home Component] USER INFO", localStorage.getItem("userInfo"))
    // Controllo su mobile_user non corretto. Sarebbe da debuggare, ma non è strettamente necessario al momento.
    // let isMobileUser= (props.auth !== undefined) ? props.auth.roles[0].roles.find((o: any,i: any) => (o === 'mobile_user') ? true: false ) : ''

    //console.log("process.env.REACT_APP_MOBILE_BOOKING ", process.env.REACT_APP_MOBILE_BOOKING)

    // TODO: anche il controllo su questa variabile potrebbe essere rimosso, in quanto non è più neccessario.
    // if (process.env.REACT_APP_MOBILE_BOOKING === "true") {

    //     // Per la corretta visualizzazione dell'app V2 in produzione non serve più.
    //     // if(isMobileUser === 'mobile_user'){
    //     allTabs = [
    //         {
    //             menuItem: formatMessage({ id: 'app.tab.locations' }),
    //             render: () => <Form />,
    //             app: '',
    //             permissions: [
    //                 { I: 'manage', a: 'Location' },
    //             ]
    //         },
    //         {
    //             menuItem: formatMessage({ id: 'app.tab.booking_v2' }),
    //             render: () => <BookingTabContainer adminView={false} name="Booking" />,
    //             app: APP.WSMANAGER,
    //             permissions: [
    //                 { I: 'create', a: 'ReservationV1' },
    //             ]
    //         },
    //         {
    //             menuItem: formatMessage({ id: 'app.tab.reservations' }),
    //             render: () => <ReservationsTabContainer isModerator={false} onClick={onTabChange()} />,
    //             app: APP.WSMANAGER,
    //             permissions: [
    //                 { I: 'update', a: 'Reservation' },
    //             ]
    //         },
    //         {
    //             menuItem: formatMessage({ id: 'app.tab.requests' }),
    //             render: () => <ReservationsTabContainer isModerator={true} onClick={onTabChange()} />,
    //             app: APP.WSMANAGER,
    //             permissions: [
    //                 { I: 'manage', a: 'Reservation' },
    //             ]
    //         },
    //         {
    //             menuItem: formatMessage({ id: 'app.tab.users' }),
    //             render: () => <UsersTabContainer />,
    //             app: '',
    //             permissions: [
    //                 { I: 'manage', a: 'User' },
    //             ]
    //         },
    //         {
    //             menuItem: formatMessage({ id: 'app.tab.biohazard' }),
    //             render: () => <BiohazardTabContainer />,
    //             app: APP.COVID19,
    //             permissions: [
    //                 { I: 'read', a: 'Biohazard' },
    //             ]
    //         },
    //         {
    //             menuItem: formatMessage({ id: 'app.tab.contact_reports' }),
    //             render: () => <ContactReportsTabContainer />,
    //             app: APP.COVID19,
    //             permissions: [
    //                 { I: 'read', a: 'ContactReports' },
    //             ]
    //         },
    //         {
    //             menuItem: formatMessage({ id: 'app.tab.checkin_reports' }),
    //             render: () => <CheckInReportsTabContainer />,
    //             app: '',
    //             permissions: [
    //                 { I: 'read', a: 'CheckInReport' },
    //             ]
    //         },
    //         {
    //             menuItem: formatMessage({ id: 'app.tab.reports' }),
    //             render: () => <ReportsTabContainer />,
    //             app: '',
    //             permissions: [
    //                 { I: 'read', a: 'Reports' },
    //             ]
    //         },
    //         {
    //             menuItem: formatMessage({ id: 'app.tab.log' }),
    //             render: () => <LogTabContainer />,
    //             app: '',
    //             permissions: [
    //                 { I: 'read', a: 'Log' },
    //             ]
    //         },
    //         {
    //             menuItem: { id: 'app.tab.settings', icon: 'settings', position: 'right' },
    //             render: () => <SettingsTabContainer />,
    //             app: '',
    //             permissions: [
    //                 { I: 'manage', a: 'Settings' },
    //             ]
    //         },

    //     ]
    //     // Non serve visualizzare la vecchia app per le prenotazioni. 
    //     // Non viene più fatto il controllo sul permesso mobile_user, poiché  il permesso booker non è più presente.
    //     // } else{
    //     //     allTabs = [
    //     //         {
    //     //             menuItem: formatMessage({ id: 'app.tab.locations' }),
    //     //             render: () => <LocationsTabContainer adminView={true} name="Manage" />,
    //     //             app: '',
    //     //             permissions: [
    //     //                 { I: 'manage', a: 'Location' },
    //     //             ]
    //     //         },
    //     //         {
    //     //             menuItem: formatMessage({ id: 'app.tab.booking' }),
    //     //             render: () => <LocationsTabContainer adminView={false} name="Booking" />,
    //     //             app: APP.WSMANAGER,
    //     //             permissions: [
    //     //                 { I: 'create', a: 'Reservation' },
    //     //             ]
    //     //         },
    //     //         {
    //     //             menuItem: formatMessage({ id: 'app.tab.reservations' }),
    //     //             render: () => <ReservationsTabContainer isModerator={false} onClick={onTabChange()} />,
    //     //             app: APP.WSMANAGER,
    //     //             permissions: [
    //     //                 { I: 'update', a: 'Reservation' },
    //     //             ]
    //     //         },
    //     //         {
    //     //             menuItem: formatMessage({ id: 'app.tab.requests' }),
    //     //             render: () => <ReservationsTabContainer isModerator={true} onClick={onTabChange()} />,
    //     //             app: APP.WSMANAGER,
    //     //             permissions: [
    //     //                 { I: 'manage', a: 'Reservation' },
    //     //             ]
    //     //         },
    //     //         {
    //     //             menuItem: formatMessage({ id: 'app.tab.users' }),
    //     //             render: () => <UsersTabContainer />,
    //     //             app: '',
    //     //             permissions: [
    //     //                 { I: 'manage', a: 'User' },
    //     //             ]
    //     //         },
    //     //         {
    //     //             menuItem: formatMessage({ id: 'app.tab.biohazard' }),
    //     //             render: () => <BiohazardTabContainer />,
    //     //             app: APP.COVID19,
    //     //             permissions: [
    //     //                 { I: 'read', a: 'Biohazard' },
    //     //             ]
    //     //         },
    //     //         {
    //     //             menuItem: formatMessage({ id: 'app.tab.contact_reports' }),
    //     //             render: () => <ContactReportsTabContainer />,
    //     //             app: APP.COVID19,
    //     //             permissions: [
    //     //                 { I: 'read', a: 'ContactReports' },
    //     //             ]
    //     //         },
    //     //         {
    //     //             menuItem: formatMessage({ id: 'app.tab.checkin_reports' }),
    //     //             render: () => <CheckInReportsTabContainer />,
    //     //             app: '',
    //     //             permissions: [
    //     //                 { I: 'read', a: 'CheckInReport' },
    //     //             ]
    //     //         },
    //     //         {
    //     //             menuItem: formatMessage({ id: 'app.tab.reports' }),
    //     //             render: () => <ReportsTabContainer />,
    //     //             app: '',
    //     //             permissions: [
    //     //                 { I: 'read', a: 'Reports' },
    //     //             ]
    //     //         },
    //     //         {
    //     //             menuItem: formatMessage({ id: 'app.tab.log' }),
    //     //             render: () => <LogTabContainer />,
    //     //             app: '',
    //     //             permissions: [
    //     //                 { I: 'read', a: 'Log' },
    //     //             ]
    //     //         },
    //     //         {
    //     //             menuItem: { id: 'app.tab.settings', icon: 'settings', position:'right'},
    //     //             render: () => <SettingsTabContainer />,
    //     //             app: '',
    //     //             permissions: [
    //     //                 { I: 'manage', a: 'Settings' }, 
    //     //             ]
    //     //         },

    //     //     ]
    //     // }
    // } else {

    //     allTabs = [
    //         {
    //             menuItem: formatMessage({ id: 'app.tab.locations' }),
    //             render: () => <LocationsTabContainer adminView={true} name="Manage" />,
    //             app: '',
    //             permissions: [
    //                 { I: 'manage', a: 'Location' },
    //             ]
    //         },
    //         {
    //             menuItem: formatMessage({ id: 'app.tab.booking' }),
    //             render: () => <LocationsTabContainer adminView={false} name="Booking" />,
    //             app: APP.WSMANAGER,
    //             permissions: [
    //                 { I: 'create', a: 'Reservation' },
    //             ]
    //         },/*
    //         {
    //             menuItem: formatMessage({ id: 'app.tab.booking' }),
    //             render: () => <BookingTabContainer adminView={false} name="Booking" />,
    //             app: APP.WSMANAGER,
    //             permissions: [
    //                 { I: 'create', a: 'Reservation' },
    //             ]
    //         },*/
    //         {
    //             menuItem: formatMessage({ id: 'app.tab.reservations' }),
    //             render: () => <ReservationsTabContainer isModerator={false} onClick={onTabChange()} />,
    //             app: APP.WSMANAGER,
    //             permissions: [
    //                 { I: 'update', a: 'Reservation' },
    //             ]
    //         },
    //         {
    //             menuItem: formatMessage({ id: 'app.tab.requests' }),
    //             render: () => <ReservationsTabContainer isModerator={true} onClick={onTabChange()} />,
    //             app: APP.WSMANAGER,
    //             permissions: [
    //                 { I: 'manage', a: 'Reservation' },
    //             ]
    //         },
    //         {
    //             menuItem: formatMessage({ id: 'app.tab.users' }),
    //             render: () => <UsersTabContainer />,
    //             app: '',
    //             permissions: [
    //                 { I: 'manage', a: 'User' },
    //             ]
    //         },
    //         {
    //             menuItem: formatMessage({ id: 'app.tab.biohazard' }),
    //             render: () => <BiohazardTabContainer />,
    //             app: APP.COVID19,
    //             permissions: [
    //                 { I: 'read', a: 'Biohazard' },
    //             ]
    //         },
    //         {
    //             menuItem: formatMessage({ id: 'app.tab.contact_reports' }),
    //             render: () => <ContactReportsTabContainer />,
    //             app: APP.COVID19,
    //             permissions: [
    //                 { I: 'read', a: 'ContactReports' },
    //             ]
    //         },
    //         {
    //             menuItem: formatMessage({ id: 'app.tab.checkin_reports' }),
    //             render: () => <CheckInReportsTabContainer />,
    //             app: '',
    //             permissions: [
    //                 { I: 'read', a: 'CheckInReport' },
    //             ]
    //         },
    //         {
    //             menuItem: formatMessage({ id: 'app.tab.reports' }),
    //             render: () => <ReportsTabContainer />,
    //             app: '',
    //             permissions: [
    //                 { I: 'read', a: 'Reports' },
    //             ]
    //         },
    //         {
    //             menuItem: formatMessage({ id: 'app.tab.log' }),
    //             render: () => <LogTabContainer />,
    //             app: '',
    //             permissions: [
    //                 { I: 'read', a: 'Log' },
    //             ]
    //         },
    //         {
    //             menuItem: { id: 'app.tab.settings', icon: 'settings', position: 'right' },
    //             render: () => <SettingsTabContainer />,
    //             app: '',
    //             permissions: [
    //                 { I: 'manage', a: 'Settings' },
    //             ]
    //         },

    //     ]

    // }

    const [tabs, setTabs] = useState([])

    useEffect(() => {
        setLoading(true)
        props.getLocations()
        props.getReasons()
        props.getUserList(1, 10, {});

        // console.log("props.locationsList!", props.locationsList, props.locationsList.length == 0)
        // if (!props.locationsList || props.locationsList.length == 0) {
        //     props.getAvailableLocations(formDetail.location, formDetail.startDate, formDetail.numberPeople)
        // }
    }, [])


    // useEffect(() => {
    //     setLoading(props.locationV2Loading)
    // }, [props.locationV2Loading])


    useEffect(() => {
        setLoading(props.locationsLoading)
    }, [props.locationsLoading])


    useEffect(() => {
        if (props.locationV2Loading && props.reasonListLoading) {
            setLoading(true)
        }
        else {
            setLoading(false)

        }
    }, [props.locationV2Loading, props.reasonListLoading])



    useEffect(() => {
        if (props.caslInitialized) {

            setCurrentTabs()
        }
    }, [props.caslInitialized])

    useEffect(() => {
        setCurrentTabs()
        setActivePane({ activeIndex: 0 })

    }, [props.activeApp])

    useEffect(() => {
        // console.log("formDetail", formDetail)
        // console.log("selectedReason", selectedReason)

        if (formDetail.endDate && !step && formDetail.numberPeople > 0 && formDetail.location && props.locationsList && props.reasonList) {
            setStep(true)
            setLoading(false)
        }
    }, [formDetail])

    // useEffect(() => {
    //     console.log("startDate", startDate)
    // }, [startDate])

    useEffect(() => {
        let newForm: FormReservation = { ...formDetail }
        newForm.startDate = startDate;
        newForm.endDate = endDate;
        setFormDetail(newForm)
    }, [startDate, endDate])

    // useEffect(() => {

    // }, [selectedLocation])


    useEffect(() => {
        if (successBooking) {
            setFormDetail(new FormReservation())
        }
    }, [successBooking])

    useEffect(() => {
        if (optionsAreas.length == 1) {
            setAreas(optionsAreas[0].key)
        }
    }, [listArea])

    useEffect(() => {
        if (optionsFloors.length == 1) {
            setFloor(optionsFloors[0].key)
        }
    }, [listFloor])


    useEffect(() => {
        switch (depthLocation) {
            case 0:
                setVisibleItem({
                    area: false,
                    floor: false,
                    room: false,
                    desk: true
                })
                break;
            case 1:
                setVisibleItem({
                    area: false,
                    floor: false,
                    room: true,
                    desk: true
                })
                console.log("getListRooms - selectedItem", selectedItem)

                getListRooms(selectedItem)

                break;
            case 2:
                setVisibleItem({
                    area: false,
                    floor: true,
                    room: true,
                    desk: true
                })
                console.log("getListFloors - selectedItem", selectedItem)

                getListFloors(selectedItem)

                break;
            case 3:
                setVisibleItem({
                    area: true,
                    floor: true,
                    room: true,
                    desk: true
                })
                console.log("getListAreas - selectedItem", selectedItem)

                getListAreas(selectedItem)

                break;


            default:
                console.log("No such day exists!");
                break;
        }
    }, [depthLocation])

    useEffect(() => {
        if (optionsRooms.length == 1) {
            setRoom(optionsRooms[0].key)
        }
    }, [listRoom])


    useEffect(() => {
        if (optionsDesks.length == 1) {
            setDesks(optionsDesks[0].key)
        }
    }, [listDesk])


    useEffect(() => {
        console.log("selectedArea", selectedArea)
        getListFloors(selectedArea)

    }, [selectedArea])

    useEffect(() => {
        console.log("selectedFloor", selectedFloor)
        getListRooms(selectedFloor)

    }, [selectedFloor])

    useEffect(() => {
        console.log("selectedRoom", selectedRoom)
        getListDesks(selectedRoom)

    }, [selectedRoom])

    // useEffect(() => {
    //     console.log("locations", props.locationsList)
    //     let element;
    //     if (selectedLocation) {
    //         nextForm()
    //     }

    // }, [selectedLocation])

    useEffect(() => {
        // let locations = LocationUtils.GetTreeDataFromFlatData(props.listLocationOptions);
        // console.log("props.listParentLocation", props.locationsList, props.listParentLocation)
        // let totalLocation = props.listParentLocation
        // let locations = LocationUtils.GetTreeDataFromFlatData(props.locationsList);

        // countDepthList(props.locationsList, null)


        // console.log("listLocationOptions", props.locationsList, locations, selectedLocation)


        // // console.log("totalLocation before", totalLocation, locations?.length, props.listLocationOptions)
        // if (totalLocation && selectedLocation) {
        //     for (let item of totalLocation) {
        //         if (item.guid == selectedLocation.guid) {
        //             item.children = locations
        //         }
        //     }
        // }
        // console.log("totalLocation after", totalLocation, locations)

        // // totalLocation && totalLocation[selectedLocation] ? totalLocation[selectedLocation.guid].children = locations : totalLocation = props.listParentLocation

        // // console.log("props.locations", selectedLocation, props.listLocationOptions, locations, totalLocation)

        getListLocation(props.listParentLocation)
    }, [props.listParentLocation])

    useEffect(() => {
        let motivations = []
        console.log("props.reasonList", props.reasonList)
        for (let item of props.reasonList) {
            motivations.push({ key: item.guid, text: item.text, value: item.guid })
        }

        setOptionsMotivation(motivations)
        setReason(motivations[0]?.key)
        // props.reasonList && props.reasonList.length > 0 ? setLoading(false) : setLoading(true)

    }, [props.reasonList])

    useEffect(() => {
        setReason(optionsMotivation[0]?.key)
        // props.reasonList && props.reasonList.length > 0 ? setLoading(false) : setLoading(true)

    }, [optionsMotivation])

    useEffect(() => {
        console.log("bookingState?.loading", props.bookingState?.loading)
        setLoading(props.bookingState?.loading ? props.bookingState?.loading : false)
    }, [props.bookingState?.loading])


    useEffect(() => {
        let users = []
        let auth: any = localStorage.getItem("auth")
        let userInfo = JSON.parse(auth)
        if (props.usersList.items) {
            for (let item of props.usersList.items) {
                users.push({ key: item.guid, text: item.name + " " + item.surname, value: item.guid })
            }
        }
        if (formDetail.selfReservation && formDetail.listUser.indexOf(userInfo.name + " " + userInfo.surname) < 1) {
            users.push({ key: userInfo.guid, text: userInfo.name + " " + userInfo.surname, value: userInfo.guid })
        }

        if (selectedUsers) {
            for (let pl of selectedUsers) {
                if (pl.guid) {
                    if (users.filter((e: { key: any; }) => e.key === pl.guid).length <= 0) {
                        users.push({ key: pl.guid, text: pl.name + " " + pl.surname, value: pl.guid })
                    }
                }
            }
        }
        setOptionsPeople(users)
        setFormDetail(formDetail)

        // if (props.usersList.items) {
        //     setSelfReservation(true)
        // }
    }, [props.usersList])


    useEffect(() => {
        console.log("selectedLocationselectedLocation area", listArea, listFloor, listRoom, listDesk)
        if (props.locationsList && props.locationsList.length > 0 && selectedLocation) {
            let element

            let locations = LocationUtils.GetTreeDataFromFlatData(props.locationsList);
            // countDepthList(props.locationsList, null)
            for (let item of locations) {
                if (item.guid == selectedLocation.guid) {
                    console.log("selectedLocationselectedLocation")
                    countDepthList(item)
                    element = item
                    setSelectedItem(element)

                }
            }
            setLoading(false)
        }
    }, [props.locationsList])

    const nextForm = () => {
        console.log("formDetail", formDetail)
        setLoading(true)
        let newForm: FormReservation = { ...formDetail }
        newForm.floor = ''
        newForm.room = ''
        newForm.desk = ''
        setFormDetail(newForm)
        props.getAvailableLocations(formDetail)
        setSelfReservation(true)
        setLoading(false)
    }

    const getListLocation = (locations: any) => {
        let listLocation = []
        if (locations) {
            for (let item of locations) {
                listLocation.push({ key: item.guid, text: item.name, value: item.guid })
            }
            setListLocation(locations)
            setOptionsLocations(listLocation)
            props.listParentLocation && props.listParentLocation.length > 0 ? setLoading(false) : setLoading(true)
        }

    }

    const countDepthList: any = (element: any, limit: any) => {
        if (!limit) {
            limit = 0
        }
        if (element && element.children) {

            limit++
            countDepthList(element.children[0] ? element.children[0] : element.children, limit)
        }
        else {
            // if (element && element.desks_properties) {

            //     limit++
            // }

            setDepthLocation(limit)
        }
        console.log("selectedLocationselectedLocation", depthLocation)
    }

    const getListAreas = (location: any) => {

        let listAreas = []
        let listOptionArea = []
        console.log("location", location)
        if (location && location.children) {
            for (let item of location.children) {

                if (item.children) {
                    listOptionArea.push({ key: item.guid, text: item.name, value: item.guid })
                    listAreas.push(item)
                }
                console.log("listAreas", listAreas)
                console.log("listOptionArea", listOptionArea)


            }
            setListArea(listAreas)
            setOptionsAreas(listOptionArea)
            if (listOptionArea.length == 1) {
                setAreas(listOptionArea[0].key)
            }
            setListFloor([])
            setOptionsFloors([])

            // else {
            //     listOptionArea.push({ key: location.guid + "-area-0", text: location.name + "-area-0", value: location.guid + "-area-0", description: 0 })
            //     listAreas.push({ guid: location.guid + "-area-0", name: location.name + "-area-0", children: location.children })
            //     console.log("listAreas", listAreas)
            //     console.log("listOptionArea", listOptionArea)

            //     setListArea(listAreas)
            //     setOptionsAreas(listOptionArea)
            //     if (listOptionArea.length == 1) {
            //         setAreas(listOptionArea[0].key)
            //     }
            //     setListFloor([])
            //     setOptionsFloors([])
            // }

        }

    }

    const getListFloors = (location: any) => {
        let listFloors = []
        let listOptionFloor = []
        console.log("getListFloors", location)
        setListFloor([])
        if (location && location.children) {

            for (let item of location.children) {

                if (item.children) {
                    listOptionFloor.push({ key: item.guid, text: item.name, value: item.guid })
                    listFloors.push(item)
                }

            }

            setListFloor(listFloors)
            setOptionsFloors(listOptionFloor)
            if (listOptionFloor.length == 1) {
                setFloor(listOptionFloor[0].key)
            }
        }
    }
    const getListRooms = (location: any) => {
        let listRooms = []
        let listOptionRoom = []
        console.log("getListRooms", location)
        setListRoom([])
        if (location && location.children) {

            for (let item of location.children) {

                if (item.desks_properties && item.seats_available > 0) {
                    listOptionRoom.push({ key: item.guid, text: item.name, value: item.guid, description: item.seats_available })
                    listRooms.push(item)
                }

            }

            setListRoom(listRooms)
            setOptionsRooms(listOptionRoom)
            if (listOptionRoom.length == 1) {
                setRoom(listOptionRoom[0].key)
            }
        }
    }



    const getListDesks = (location: any) => {
        let listDesks = []
        let listOptionDesk = []
        setListDesk([])
        console.log("getListDesks", location)

        if (location && location.desks_properties) {

            for (let item of location.desks_properties) {

                if (item) {
                    listOptionDesk.push({ key: item.guid, text: item.name, value: item.guid, description: item.seats_available })
                    listDesks.push(item)
                }
            }

            setListDesk(listDesks)
            setOptionsDesks(listOptionDesk)
            if (listOptionDesk.length == 1) {
                setDesks(listOptionDesk[0].key)
            }
        }
    }

    // const setReservationDate = (newDate: any) => {
    //   console.log("setReservationDate")
    //   let newForm: FormReservation = formDetail
    //   newForm.range = newDate
    //   setFormDetail(newForm)
    // }

    const setLocation = (location: any) => {
        console.log("setLocation")
        let newForm: FormReservation = { ...formDetail }
        newForm.location = location
        newForm.floor = ''
        newForm.room = ''
        newForm.desk = ''
        setFormDetail(newForm)
        setOptionsFloors([])
        setOptionsAreas([])
        setOptionsRooms([])
        setOptionsDesks([])
        setDepthLocation(-1)

        for (let loc of listLocation) {
            if (loc.guid === location) {
                location = loc
                setSelectedLocation(location)
            }
        }
    }

    const setFloor = (floor: any) => {
        console.log("setFloor")
        let newForm: FormReservation = { ...formDetail }
        newForm.floor = floor
        newForm.desk = ''
        newForm.room = ''
        setFormDetail(newForm)
        setOptionsRooms([])
        setOptionsDesks([])

        for (let item of listFloor) {
            console.log(item, floor)
            if (item.guid === floor) {
                floor = item
                setSelectedFloor(floor)
            }
        }
    }

    const setDesks = (desk: any) => {
        console.log("setDesks")
        let newForm: FormReservation = { ...formDetail }
        newForm.desk = desk

        setFormDetail(newForm)

        for (let item of listDesk) {
            if (item.guid === desk) {
                desk = item

                setSelectedDesk(desk)
            }
        }
    }

    const setAreas = (area: any) => {
        console.log("setAreas", area)
        let newForm: FormReservation = { ...formDetail }
        newForm.area = area
        newForm.floor = ''
        newForm.room = ''
        setFormDetail(newForm)
        // setOptionsFloors([])
        setOptionsRooms([])
        setOptionsDesks([])
        for (let item of listArea) {
            console.log("setAreas", area, item.guid)

            if (item.guid === area) {
                area = item

                setSelectedArea(area)
            }
        }

    }

    const setRoom = (room: any) => {
        console.log("setRoom", room)
        let newForm: FormReservation = { ...formDetail }
        newForm.room = room
        newForm.desk = ''
        setFormDetail(newForm)
        setOptionsDesks([])

        for (let item of listRoom) {
            console.log(item, room)
            if (item.guid === room) {
                console.log("setRoomFIND")

                room = item
                setSelectedRoom(room)
            }
        }
    }

    const setReason = (reason: any) => {
        console.log("setReason", reason)
        let newForm: FormReservation = { ...formDetail }
        newForm.reason = reason
        setFormDetail(newForm)
        for (let item of optionsMotivation) {
            console.log("setReason2", item.key === reason)

            if (item.key === reason) {
                reason = item
                setSelectedReason(reason)
                return
            }
        }
    }


    /* const setPeoples = (event: any, data: any) => {
        console.log("setPeoples", data)
        let newForm: FormReservation = { ...formDetail }
        if (data.length <= formDetail.numberPeople) {
            newForm.listUser = data
            setFormDetail(newForm)

            let listUserToSelected = []
            for (let item of selectedUsers) {
                if (!item.guid) {

                    // listUserToSelected.push(item)
                }
                else {
                    for (let user of data) {
                        if (item.guid == user && listUserToSelected.filter((e: { guid: any; }) => e.guid === item.guid).length <= 0) {
                            console.log("ECCOLO", user, item.guid, item.surname, listUserToSelected)
                            listUserToSelected.push(item)
                        }
                    }
                }
            }
            for (let item of props.usersList.items) {
                for (let pl of data) {
                    if (item.guid == pl && newForm.numberPeople >= listUserToSelected.length && listUserToSelected.filter((e: { guid: any; }) => e.guid === item.guid).length <= 0) {
                        listUserToSelected.push(item)
                    }
                }
            }
            setSelectedUsers(listUserToSelected)
        }
    }; */

    const setPeoples = (event: any, data: any) => {
        console.log("setPeoples", data)
        let newForm: FormReservation = { ...formDetail }
        
        if (data.length <= formDetail.numberPeople) {

            // AEP-5280
            // Verifica se l'utente corrente è presente nella nuova lista
            const userInfo = JSON.parse(localStorage.getItem("auth") || '{}');
            const isUserIncluded = data.includes(userInfo.guid);
            
            // Aggiorna il valore di selfReservation in base alla presenza dell'utente
            setSelfReservation(isUserIncluded);
            // AEP-5280
            
            newForm.listUser = data
            setFormDetail(newForm)
            let listUserToSelected = []
            
            for (let item of selectedUsers) {
                if (!item.guid) {
                    // listUserToSelected.push(item)
                }
                else {
                    for (let user of data) {
                        if (item.guid == user && listUserToSelected.filter((e: { guid: any; }) => e.guid === item.guid).length <= 0) {
                            console.log("ECCOLO", user, item.guid, item.surname, listUserToSelected)
                            listUserToSelected.push(item)
                        }
                    }
                }
            }
            
            for (let item of props.usersList.items) {
                for (let pl of data) {
                    if (item.guid == pl && newForm.numberPeople >= listUserToSelected.length && listUserToSelected.filter((e: { guid: any; }) => e.guid === item.guid).length <= 0) {
                        listUserToSelected.push(item)
                    }
                }
            }
            setSelectedUsers(listUserToSelected)
        }
    };


    const setCurrentTabs = () => {
        let tabs: any = []
        setTabs(tabs)

        for (var i in allTabs) {
            let tab = allTabs[i]

            if (tab.app === '' || tab.app === props.activeApp) {
                for (var x in tab.permissions) {
                    let rule = tab.permissions[parseInt(x)]

                    if (ability.can(rule.I, rule.a)) {
                        tabs.push(tab)
                        break
                    }
                }
            }
        }
        setTabs(tabs)
    }

    const onTabChange = () => {
        props.onSwitchTab();
    }

    const setActivePane = (data: any) => {
        if (data.activeIndex !== activeIndex) {
            setActiveIndex(data.activeIndex)
        }
    }

    const handleGetLocations = () => {
        console.log("[Home Component] getLocations")
        props.getLocations()
    }

    const handleReasonList = () => {
        console.log("[Home Component] reasonList")
        //props.listReasons()
        props.getReasons()
    }

    const handleGetColleagues = (page: any, limit: any, filters: any) => {
        console.log("[Home Container] getColleagues", page, " - with filters: ", filters)
        // let body = {
        //     per_page: 10,
        //     page: 0,
        //     filters: {}
        // }
        // props.getColleagues()
        props.getUserList(page, limit, filters);
    }

    const setSelfReservation = (checked: any) => {
        console.log("setSelfReservation", checked)
        let auth: any = localStorage.getItem("auth")
        let userInfo = JSON.parse(auth)
        let newForm: FormReservation = { ...formDetail }
        newForm.selfReservation = checked
        let listUserToSelected: any[] = selectedUsers

        if (checked) {
            if (newForm.listUser.indexOf(userInfo.guid) < 0 && newForm.numberPeople > formDetail.listUser.length) {
                newForm.listUser.push(userInfo.guid)
            }

            if (listUserToSelected.indexOf(userInfo.name + " " + userInfo.surname) < 0 && newForm.numberPeople > listUserToSelected.length) {
                listUserToSelected.push(userInfo.name + " " + userInfo.surname)

            }
        }
        else {
            const index = newForm.listUser.indexOf(userInfo.guid);
            newForm.listUser.splice(index, 1);
            const indexR = listUserToSelected.indexOf(userInfo.name + " " + userInfo.surname);
            listUserToSelected.splice(indexR, 1);
        }

        for (let item of props.usersList.items) {
            for (let pl of newForm.listUser) {
                if (item.guid == pl && listUserToSelected.indexOf(userInfo.name + " " + userInfo.surname) < 0 && newForm.numberPeople >= listUserToSelected.length && listUserToSelected.filter((e: { guid: any; }) => e.guid === item.guid).length <= 0) {
                    listUserToSelected.push(item)
                }
            }
        }

        setSelectedUsers(listUserToSelected)
        setFormDetail(newForm)


    }

    const setNumberPeople = (numberPeople: number) => {
        let auth: any = localStorage.getItem("auth")
        let userInfo = JSON.parse(auth)
        let newForm: FormReservation = { ...formDetail }

        console.log("setNumberPeople")
        newForm.numberPeople = numberPeople
        newForm.listUser = []
        if (newForm.selfReservation) {
            if (newForm.listUser.indexOf(userInfo.guid)) {
                newForm.listUser.push(userInfo.guid)
            }
        }
        else {
            const index = newForm.listUser.indexOf("d");
            newForm.listUser.splice(index, 1);
        }

        let listUserToSelected = []
        for (let item of props.usersList.items) {
            for (let pl of newForm.listUser) {
                if (item.guid == pl && newForm.numberPeople >= listUserToSelected.length) {
                    listUserToSelected.push(item.name + " " + item.surname)
                }
            }
        }
        setSelectedUsers(listUserToSelected)

        setFormDetail(newForm)
    }

    const setDates = (e: any) => {
        setRange(e.value)

        if (e.value?.length > 1) {
            let tmp: any = e.value[0]
            setStartDate(moment(tmp).format('YYYY-MM-DD').split("T")[0])
            let tmp2: any = e.value[1]
            setEndDate(moment(tmp2).format('YYYY-MM-DD').split("T")[0])


        }

    }

    const lessSeats = () => {
        console.log("lessSeats")

        let auth: any = localStorage.getItem("auth")
        let userInfo = JSON.parse(auth)
        let newForm: FormReservation = { ...formDetail }
        if (formDetail.numberPeople - 1 > 0) {
            newForm.numberPeople = formDetail.numberPeople - 1
            if (newForm.listUser?.length > newForm.numberPeople) {


                newForm.listUser = []
                if (newForm.selfReservation) {
                    if (newForm.listUser.indexOf(userInfo.guid)) {
                        newForm.listUser.push(userInfo.guid)
                    }
                }
                else {
                    const index = newForm.listUser.indexOf("d");
                    newForm.listUser.splice(index, 1);
                }
            }
            let listUserToSelected = []
            for (let item of props.usersList.items) {
                for (let pl of newForm.listUser) {
                    if (item.guid == pl && newForm.numberPeople >= listUserToSelected.length) {
                        listUserToSelected.push(item.name + " " + item.surname)
                    }
                }
            }
            console.log("listUserToSelected", listUserToSelected)
            setSelectedUsers(listUserToSelected)
            setDepthLocation(-1)

            setFormDetail(newForm)
        }
    }

    const plusSeats = () => {
        console.log("plusSeats")
        let newForm: FormReservation = { ...formDetail }
        newForm.numberPeople = formDetail.numberPeople + 1
        setDepthLocation(-1)
        setFormDetail(newForm)
    }

    // useEffect(() => {
    //     let locations = LocationUtils.GetTreeDataFromFlatData(props.locationsList);
    //     console.log("[Home Component] props.locationsList", locations)
    // }, [props.locationsList])


    // useEffect(() => {
    //     console.log("useEffect HOME formDetail", formDetail)
    //     console.log("useEffect HOME formDetail", formDetail)
    //     console.log(formDetail)
    //     console.log(formDetail)

    // }, [formDetail])



    return showList ?
        <ReservationsTabContainer isModerator={false} onClick={onTabChange()} /> : !stepConfirmForm ? <Form
            formDetail={formDetail}
            setFormDetail={setFormDetail}
            stepForm={stepForm}
            setStepForm={setStepForm}
            stepConfirmForm={stepConfirmForm}
            setStepConfirmForm={setStepConfirmForm}
            locationsList={props.locationsList}
            getLocations={handleGetLocations}
            reasonList={props.reasonList}
            getReasons={handleReasonList}
            usersList={props.usersList}
            getUserList={handleGetColleagues}
            optionsLocations={optionsLocations}
            optionsAreas={optionsAreas}
            optionsDesks={optionsDesks}
            optionsFloors={optionsFloors}
            optionsRooms={optionsRooms}
            setLocation={setLocation}
            setAreas={setAreas}
            setFloor={setFloor}
            setDesks={setDesks}
            setRoom={setRoom}
            getListLocation={getListLocation}
            setPeoples={setPeoples}
            setReason={setReason}
            optionsMotivation={optionsMotivation}
            setOptionsPeople={setOptionsPeople}
            setOptionsMotivation={setOptionsMotivation}
            optionsPeople={optionsPeople}
            loading={loading}
            setLoading={setLoading}
            setSelfReservation={setSelfReservation}
            range={range}
            setDates={setDates}
            setNumberPeople={setNumberPeople}
            lessSeats={lessSeats}
            plusSeats={plusSeats}
            step={step}
            endDate={endDate}
            setEndDate={setEndDate}
            depthLocation={depthLocation}
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
            visibleItem={visibleItem}
            nextForm={nextForm}
            successBooking={successBooking}
        ></Form> : <ConfirmForm
            loading={loading}
            setLoading={setLoading}
            formDetail={formDetail}
            setFormDetail={setFormDetail}
            stepForm={stepForm}
            setStepForm={setStepForm}
            stepConfirmForm={stepConfirmForm}
            setStepConfirmForm={setStepConfirmForm}
            locationsList={props.locationsList}
            getLocations={handleGetLocations}
            onBooking={props.onBooking}
            selectedArea={selectedArea}
            selectedDesk={selectedDesk}
            selectedFloor={selectedFloor}
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
            successBooking={successBooking}
            setSuccessBooking={setSuccessBooking}
            selectedRoom={selectedRoom}
            selectedReason={selectedReason}
            selectedUsers={selectedUsers}
            bookingState={props.bookingState}
        ></ConfirmForm>
    //     return <Tab
    //         className="h-100 w-100 _flex flex-direction-column px-3"
    //         activeIndex={activeIndex}
    //         onTabChange={(e: any, data: any) => { setActivePane(data) }}
    //         panes={tabs}
    //     />
}

export default injectIntl(NewReservation)